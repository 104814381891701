<template>
  <div class="id-card">
    <div :class="getCardHeader()">{{ props.companyName }}</div>
    <div class="card-body" :class="getBGcolor()">
      <div class="photo">
        <img :src="photoUrl" alt="Photo" />
      </div>
      <div class="details">
        <div class="name">{{ props.name }}</div>
        <div class="id-number">
          Precinct: <strong>{{ props.idNumber }}</strong>
        </div>
        <div class="contact-info fw-bold mt-1">{{ props.contactInfo }}</div>
      </div>
    </div>
    <!-- <img :src="logoUrl" alt="Logo" class="logo" /> -->
  </div>
</template>

<script>
export default {
  props: {
    companyName: String,
    photoUrl: String,
    name: String,
    idNumber: String,
    contactInfo: String,
    logoUrl: String,
    cityId: Number,
  },
  setup(props) {
    const getBGcolor = () => {
      switch (props.cityId) {
        case 3:
          return "card-body-bg-kananga";
        default:
          return "card-body-bg-default";
      }
    };
    const getCardHeader = () => {
      switch (props.cityId) {
        case 3:
          return "card-header-3";
        default:
          return "card-header-default";
      }
    };
    return {
      props,
      getBGcolor,
      getCardHeader,
    };
  },
};
</script>

<style scoped>
.id-card {
  width: 85mm; /* Width for horizontal layout */
  height: 50mm; /* Height for horizontal layout */
  border: 1px solid #ddd; /* Light border for a cleaner look */
  border-radius: 4mm; /* Rounded corners for the whole card */
  font-family: Arial, sans-serif;
  position: relative;
  margin: 2mm;
  background-color: #f9f9f9; /* Light background color */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Soft shadow */
  display: flex;
  flex-direction: column; /* Column layout for vertical stacking */
}

/* Rounded top corners for header */
.card-header-default {
  background-color: #f12b2b; /* Red header background */
  color: #fff; /* White text color for readability */
  padding: 2mm;
  text-align: center;
  font-size: 16px; /* Larger font size for the company name */
  font-weight: bold;
  border-bottom: 1px solid #ddd; /* Border under the header */
  border-top-left-radius: 4mm; /* Match top-left border radius */
  border-top-right-radius: 4mm; /* Match top-right border radius */
}

.card-header-3 {
  background-color: #068b23; /* Red header background */
  color: #fff; /* White text color for readability */
  padding: 2mm;
  text-align: center;
  font-size: 16px; /* Larger font size for the company name */
  font-weight: bold;
  border-bottom: 1px solid #ddd; /* Border under the header */
  border-top-left-radius: 4mm; /* Match top-left border radius */
  border-top-right-radius: 4mm; /* Match top-right border radius */
}

/* Gradient background for the card body */
.card-body {
  display: flex;
  flex: 1;
  padding: 4mm;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Distribute space between photo and details */
  border-bottom-left-radius: 4mm; /* Match bottom-left border radius */
  border-bottom-right-radius: 4mm; /* Match bottom-right border radius */
  /* Light gray to red gradient */
}

.card-body-bg-default {
  background: linear-gradient(45deg, #d3d3d3, #f12b2b);
}

.card-body-bg-kananga {
  background: linear-gradient(45deg, #d3d3d3, #068b23); /* Light gray to red gradient */
}

/* Photo styling */
.photo {
  width: 25mm; /* Slightly smaller photo for better layout */
  height: 25mm;
  padding: 6px;
  border-radius: 5mm; /* Slightly rounded photo */
  background-color: #e0e0e0; /* Light gray background for the photo */
  margin-right: 6mm;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo img {
  width: 100%;
  height: auto;
}

/* Details section */
.details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  font-size: 14px; /* Slightly smaller font size for name */
  font-weight: bold;
  word-wrap: break-word; /* Ensure text wraps properly */
  overflow-wrap: break-word; /* Handle long words */
  hyphens: auto; /* Add hyphens if needed */
  margin-bottom: 2mm; /* Space below the name */
}

.id-number,
.contact-info {
  font-size: 12px; /* Smaller font size for other details */
  color: #2c2b2b; /* Slightly darker gray for better readability */
}

/* Logo styling */
.logo {
  width: 20mm; /* Adjust logo size */
  height: auto;
  position: absolute;
  bottom: 5mm;
  right: 5mm;
}
</style>
