<template>
  <div class="row my-3">
    <div class="form-check col-lg-2">
      <input
        id="showMun"
        type="checkbox"
        v-model="showMunicipalities"
        class="fs-1 me-2"
        @change="handleShowMunChange"
      />
      <label for="showMun">Show Municipalities</label>
    </div>
    <div class="form-check col-lg-2">
      <input
        id="showbrgy"
        type="checkbox"
        v-model="showBrgys"
        class="fs-1 me-2"
        @change="handleShowBrgyChange"
      />
      <label for="showbrgy">Show Barangays</label>
    </div>
    <div class="form-check col-lg-2">
      <input
        id="showsitio"
        type="checkbox"
        v-model="showSitios"
        class="fs-1 me-2"
        @change="handleShowSitioChange"
      />
      <label for="showsitio">Show Sitios</label>
    </div>
    <div class="form-check col-lg-2">
      <input
        id="showhh"
        type="checkbox"
        v-model="showHH"
        class="fs-1 me-2"
        @change="handleShowHHChange"
      />
      <label for="showhh">Show Households</label>
    </div>
    <div class="col-lg-4">
      <form @submit.prevent="handleSearch">
        <div class="input-group">
          <input
            type="text"
            v-model="keyword"
            required
            placeholder="Enter search keyword"
            class="form-control"
          />
          <button class="btn btn-secondary btn-sm" type="submit">
            <i class="bi bi-search"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div ref="mapDiv" style="width: 100%; height: 80vh" />
  <MediumSizedModal :title="modalTitle" @close="toggleModal" :show="showModal">
    <MunicipalMapInfo
      v-if="modalAction === 'summary'"
      :id="currentId"
      :type="polygonType"
    />
    <HouseholdContent
      v-else-if="modalAction === 'household'"
      :coordinates="currentCoordinates"
      :personId="personId"
    />
    <GeoVoterSearchResult
      v-else
      :persons="geosearchresult"
      @locate-person="onPersonLocated"
    />
  </MediumSizedModal>
</template>

<script>
/* eslint-disable no-undef */

import { ref, onMounted, onUnmounted } from "vue";
import areaService from "@/services/areaService";
import { parseCoordinatesString } from "@/utils/gmapHelper";
import MediumSizedModal from "../modals/MediumSizedModal.vue";
import MunicipalMapInfo from "./MunicipalMapInfo.vue";
import HouseholdContent from "./HouseholdContent.vue";
import spinnerService from "@/services/spinnerService";
import GeoVoterSearchResult from "./GeoVoterSearchResult.vue";
import loader from "@/utils/googlemaps-config";
function debounce(func, delay) {
  let timer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

export default {
  components: {
    MediumSizedModal,
    MunicipalMapInfo,
    HouseholdContent,
    GeoVoterSearchResult,
  },
  setup() {
    const mapDiv = ref(null);
    const currentId = ref(0);
    const currentHHId = ref(null);
    const currentCoordinates = ref(null);
    const polygonType = ref("mun");
    const showBrgys = ref(false);
    const showSitios = ref(false);
    const geosearchresult = ref([]);
    const personId = ref(0);
    const showHH = ref(false);
    const showMunicipalities = ref(false);
    const keyword = ref("");
    const map = ref(null);
    const modalAction = ref("summary");
    const polygonsArray = [];
    const labelsArray = [];
    const modalTitle = ref("Polygon Information");
    const showModal = ref(false);
    let clickListener = null;
    const toggleModal = () => {
      if (showModal.value === true) {
        currentId.value = 0;
        currentCoordinates.value = null;
      }
      showModal.value = !showModal.value;
    };

    const saveUserSession = () => {
      const pref = {
        showMunicipalities: showMunicipalities.value,
        keyword: keyword.value,
        showBrgys: showBrgys.value,
        showHH: showHH.value,
        showSitios: showSitios.value,
      };
      localStorage.setItem("heatmap-pref", JSON.stringify(pref));
    };

    const handleSearch = async () => {
      spinnerService.show();
      saveUserSession();
      const response = await areaService.searchGeoVoter(keyword.value);
      geosearchresult.value = response;
      spinnerService.hide();
      modalAction.value = "geosearch";
      toggleModal();
    };
    const getMunicipalVoterSummary = async (id, title) => {
      polygonType.value = "mun";
      modalAction.value = "summary";
      currentId.value = id;
      modalTitle.value = title;
      toggleModal();
    };
    const getBrgyVoterSummary = (id, title) => {
      polygonType.value = "brgy";
      modalAction.value = "summary";
      currentId.value = id;
      modalTitle.value = title;
      toggleModal();
    };

    const getSitioVoterSummary = (id, title) => {
      polygonType.value = "sitio";
      modalAction.value = "summary";
      currentId.value = id;
      modalTitle.value = title;
      toggleModal();
    };

    const getHouseholdContent = (id, title, pos) => {
      modalAction.value = "household";
      currentHHId.value = id;
      modalTitle.value = "Household Information";
      currentCoordinates.value = pos;
      toggleModal();
    };
    const handleShowBrgyChange = async () => {
      saveUserSession();
      if (showBrgys.value === true) {
        changeMunicipalTint("#FFFFFF");

        spinnerService.show();
        const brgys = await areaService.getBrgysWithPolygons();
        for (const brgy of brgys) {
          const coords = parseCoordinatesString(brgy.area_brgy);
          redrawEditablePolygon(
            map.value,
            brgy.id_brgy,
            brgy.cityid,
            "brgy",
            brgy.name_brgy,
            brgy.totalSupporters > brgy.totalNonSupporters,
            coords
          );
        }
        if (showSitios.value === true) {
          changeBrgyTint("#FFFFFF");
        }
        spinnerService.hide();
      } else {
        clearPolygons("brgy");
        changeMunicipalTintByStatus();
      }
    };
    const handleShowSitioChange = async () => {
      saveUserSession();
      if (showSitios.value === true) {
        changeMunicipalTint("#FFFFFF");
        changeBrgyTint("#FFFFFF");
        spinnerService.show();
        const sitios = await areaService.getSitioWithPolygons();
        for (const sitio of sitios) {
          const coords = parseCoordinatesString(sitio.area_sitio);
          redrawEditablePolygon(
            map.value,
            sitio.id_sitio,
            sitio.cityid_sitio,
            "sitio",
            sitio.name_sitio,
            sitio.totalSupporters > sitio.totalNonSupporters,
            coords
          );
        }
        spinnerService.hide();
      } else {
        clearPolygons("sitio");
        changeMunicipalTintByStatus();
        changeBrgyTintByStatus();
      }
    };

    const handleShowHHChange = async () => {
      saveUserSession();
      if (showHH.value === true) {
        fetchMarkersFromDb(map.value.getBounds());
      } else {
        //
        clearMarkers();
      }
    };

    const handleShowMunChange = async () => {
      saveUserSession();
      if (showMunicipalities.value === true) {
        //
        await fetchMunicipalitiesFromDb();
      } else {
        //
        clearPolygons("mun");
      }
    };

    const fetchMarkersFromDb = async (bounds) => {
      if (map.value.zoom > 12) {
        const markersInBound = await areaService.fetchMarkersFromDatabase(bounds);
        if (markersInBound === null || markersInBound.length === 0) return;
        markersInBound.forEach((markerData) => {
          addMarkerIfNotExists(
            markerData.lat_hh,
            markerData.lng_hh,
            markerData.name_hh,
            markerData.syspk_hh
          );
        });
      }
    };

    const clearMarkers = () => {
      markersMap.forEach(function (marker) {
        marker.setMap(null);
      });
      markersMap.clear();
    };
    const hotspotMarker = ref(null);
    const isHotspot = (lat, lng) => {
      return currentLat.value === lat && currentLng.value == lng;
    };
    const markersMap = new Map();
    const addMarkerIfNotExists = (lat, lng, title, id) => {
      // Check if the marker already exists in the map
      const key = lat + "" + lng;
      if (!markersMap.has(key)) {
        const ishs = isHotspot(lat, lng);
        const markerColor = ishs ? "blue" : "red";

        var marker = new google.maps.Marker({
          position: { lat: lat, lng: lng },
          map: map.value,
          title: title,
          customProperties: { id: id },
          icon: `http://maps.google.com/mapfiles/ms/icons/${markerColor}-dot.png`, // Set marker color
        });
        if (ishs) {
          if (hotspotMarker.value !== null) {
            hotspotMarker.value.setIcon(
              `http://maps.google.com/mapfiles/ms/icons/red-dot.png`
            ); // Revert color
          }
          hotspotMarker.value = marker;
        }
        var infowindow = new google.maps.InfoWindow();

        marker.addListener("click", async () => {
          infowindow.setContent(title);
          // infowindow.open(map.value, marker);
          getHouseholdContent(id, title, marker.position);
          /* $.get(`/Voter/HouseHold?handler=GetHouseHoldContent`, { houseId: id })
            .done(function (data) {
              infowindow.setContent(data);
            })
            .fail(function (err) {
              toastr.error(err.responseText);
            }); */
        });

        markersMap.set(key, marker);
      }
    };

    const changeMunicipalTintByStatus = () => {
      polygonsArray.forEach(function (polygon) {
        if (polygon.type == "mun") {
          if (polygon.id !== 3) {
            polygon.setOptions({
              fillColor: polygon.isLeading ? "#FF0000" : "#0000FF",
            });
          } else {
            polygon.setOptions({
              fillColor: polygon.isLeading ? "##006400" : "#0000FF",
            });
          }
        }
      });
    };

    const changeMunicipalTint = (tintcolor) => {
      polygonsArray.forEach(function (polygon) {
        if (polygon.type == "mun") {
          polygon.setOptions({
            fillColor: tintcolor,
          });
        }
      });
    };

    const changeBrgyTint = (tintcolor) => {
      polygonsArray.forEach(function (polygon) {
        if (polygon.type == "brgy") {
          polygon.setOptions({
            fillColor: tintcolor,
          });
        }
      });
    };
    const changeBrgyTintByStatus = () => {
      polygonsArray.forEach(function (polygon) {
        if (polygon.type == "brgy") {
          if (polygon.cityid !== 3) {
            polygon.setOptions({
              fillColor: polygon.isLeading ? "#FF0000" : "#0000FF",
            });
          } else {
            polygon.setOptions({
              fillColor: polygon.isLeading ? "#006400" : "#0000FF",
            });
          }
        }
      });
    };

    const fetchMunicipalitiesFromDb = async () => {
      if (!showMunicipalities.value) return;
      const muns = await areaService.getMunicipalitiesWithPolygons();
      for (const mun of muns) {
        const coords = parseCoordinatesString(mun.area_mun);
        redrawEditablePolygon(
          map.value,
          mun.id_mun,
          mun.id_mun,
          "mun",
          mun.name_mun,
          mun.totalSupporters > mun.totalNonSupporters,
          coords
        );
      }
    };

    const clearPolygons = (type) => {
      polygonsArray.forEach(function (polygon) {
        if (polygon.type == type) polygon.setMap(null);
      });
      labelsArray.forEach(function (label) {
        if (label.customProperties.type == type) label.setMap(null);
      });
    };

    onMounted(async () => {
      try {
        const prevSession = JSON.parse(localStorage.getItem("heatmap-pref"));
        if (prevSession && prevSession !== null) {
          showMunicipalities.value = prevSession.showMunicipalities;
          showBrgys.value = prevSession.showBrgys;
          showHH.value = prevSession.showHH;
          showSitios.value = prevSession.showSitios;
          keyword.value = prevSession.keyword;
        }
        await loader.load();
        map.value = new google.maps.Map(mapDiv.value, {
          center: { lat: 11.074797, lng: 124.58852 },
          zoom: 11,
        });
        await fetchMunicipalitiesFromDb();
        await handleShowBrgyChange();
        await handleShowSitioChange();
        const debouncedFetchMarkers = debounce(fetchMarkersFromDb, 500);

        map.value.addListener("bounds_changed", async () => {
          if (showHH.value) {
            const newBounds = map.value.getBounds(); // Get the updated bounds of the map
            debouncedFetchMarkers(newBounds);
          }
        });
      } catch (error) {
        console.error("Error loading Google Maps API or fetching data:", error);
      }
    });
    const currentLat = ref(0);
    const currentLng = ref(0);
    const onPersonLocated = (person) => {
      if (showHH.value === false) {
        showHH.value = true;
      }
      const key = currentLat.value + "" + currentLng.value;
      const oldhotspotMarker = markersMap.get(key);
      if (oldhotspotMarker && oldhotspotMarker !== null) {
        oldhotspotMarker.setIcon(`https://maps.google.com/mapfiles/ms/icons/red-dot.png`);
      }

      currentLat.value = person.lat_hh;
      currentLng.value = person.lng_hh;
      const newkey = currentLat.value + "" + currentLng.value;
      const newhotspotMarker = markersMap.get(newkey);
      if (newhotspotMarker && newhotspotMarker !== null) {
        newhotspotMarker.setIcon(
          `https://maps.google.com/mapfiles/ms/icons/blue-dot.png`
        );
      }
      personId.value = person.syspk_voter;
      map.value.setCenter(new google.maps.LatLng(person.lat_hh, person.lng_hh));
      map.value.setZoom(23);
      toggleModal();
    };
    const redrawEditablePolygon = (
      map,
      id,
      cityid,
      type,
      name,
      isLeading,
      coordinates
    ) => {
      if (!map || !coordinates) return;

      let fillColor = isLeading ? "#FF0000" : "#0000FF";
      if (isLeading && cityid === 3) {
        fillColor = "#006400";
      }
      const strokeColor = "#000000";

      const polygon = new google.maps.Polygon({
        paths: coordinates,
        map: map,
        fillColor: fillColor,
        strokeColor: strokeColor,
        editable: false,
      });

      if (type === "brgy") {
        polygon.setOptions({ zIndex: 100 });
      } else if (type === "sitio") {
        polygon.setOptions({ zIndex: 200 });
      }

      polygon.id = id;
      polygon.name = name;
      polygon.isLeading = isLeading;
      polygon.type = type;
      polygon.cityid = cityid;
      const bounds = new google.maps.LatLngBounds();
      coordinates.forEach((point) => {
        bounds.extend(new google.maps.LatLng(point.lat, point.lng));
      });
      map.fitBounds(bounds);

      const label = new google.maps.Marker({
        position: bounds.getCenter(),
        map: map,
        label: {
          text: name,
          fontSize: type === "mun" ? "18px" : "10px",
          fontWeight: "bold",
          color: isLeading ? "#000000" : "#800080",
        },
        icon: {
          url: "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=",
          size: new google.maps.Size(1, 1),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0),
        },
        optimized: false,
        customProperties: { id, name, type, cityid },
      });

      google.maps.event.addListener(polygon, "click", () => {
        if (polygon.type === "mun") {
          getMunicipalVoterSummary(polygon.id, polygon.name);
        } else if (polygon.type === "brgy") {
          getBrgyVoterSummary(polygon.id, polygon.name);
        } else {
          getSitioVoterSummary(polygon.id, polygon.name);
        }
      });

      polygonsArray.push(polygon);
      labelsArray.push(label);
      return polygon;
    };

    onUnmounted(() => {
      if (clickListener) {
        clickListener.remove();
      }
      polygonsArray.forEach((polygon) => polygon.setMap(null));
      labelsArray.forEach((label) => label.setMap(null));
      map.value = null;
    });

    return {
      mapDiv,
      toggleModal,
      showModal,
      currentId,
      modalTitle,
      showBrgys,
      handleShowBrgyChange,
      showHH,
      handleShowHHChange,
      polygonType,
      modalAction,
      currentHHId,
      currentCoordinates,
      handleSearch,
      keyword,
      geosearchresult,
      onPersonLocated,
      personId,
      showMunicipalities,
      handleShowMunChange,
      showSitios,
      handleShowSitioChange,
    };
  },
};
</script>

<style>
#map {
  height: 400px;
  width: 100%;
}
</style>
