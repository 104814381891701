<template>
  <div class="file-upload-container shadow rounded py-3">
    <h3 class="mb-3"><i class="bi bi-upload"></i> Supporters Only Upload</h3>
    <div class="form-group text-start mx-3">
      <label>Select City/Municipality</label>
      <select class="form-select" v-model="cityId">
        <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
          {{ city.name_mun }}
        </option>
      </select>
    </div>

    <input
      ref="fileInput"
      type="file"
      @change="handleFileUpload"
      accept=".xlsx, .xls"
      style="display: none"
    />

    <!-- Custom element that triggers the file input -->
    <div
      style="cursor: pointer"
      v-if="!isProcessing"
      class="custom-upload-button"
      @click="triggerFileUpload"
    >
      <i class="bi bi-cloud-upload"></i>
      <p>Upload Excel File</p>
    </div>

    <div v-if="errors.length" class="errors-container text-danger">
      <p>Please correct the following errors:</p>
      <ul>
        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
      </ul>
    </div>

    <div v-if="isProcessing" class="progress-container">
      <div class="icon-container mt-2">
        <i class="bi bi-file-earmark-spreadsheet"></i>
        <div
          class="progress-circle"
          :style="{ strokeDasharray: progressDasharray }"
        ></div>
      </div>
      <p>Uploading {{ uploadedRows }} of {{ totalRows }} rows...</p>
    </div>

    <div
      v-if="successMessage"
      class="alert alert-success mx-2 d-flex justify-content-center align-items-center"
    >
      <p>{{ successMessage }}</p>
    </div>
  </div>
  <div v-if="successMessage">
    <h3 class="text-center">
      {{ totalRows - unknownVoters.length }} / {{ totalRows }} Updated
    </h3>
    <div class="text-end" v-if="unknownVoters.length > 0">
      <button
        type="button"
        @click.prevent="downloadVoterList"
        class="btn btn-sm btn-secondary"
        title="Download"
      >
        <i class="bi bi-download"></i>
      </button>
    </div>
    <div class="table-responsive">
      <table v-show="unknownVoters.length > 0" class="table align-middle">
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(voter, index) in unknownVoters" :key="index">
            <td>{{ voter.name_voter }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import areaService from "@/services/areaService";
import spinnerService from "@/services/spinnerService";
import toastService from "@/services/toastService";
import voterService from "@/services/voterService";
import { ref, onMounted } from "vue";
import * as XLSX from "xlsx";

export default {
  setup() {
    const errors = ref([]);
    const successMessage = ref("");
    const isProcessing = ref(false);
    const uploadedRows = ref(0);
    const totalRows = ref(0);
    const progressDasharray = ref("0 100");
    const fileInput = ref(null);
    const cities = ref([]);
    const cityId = ref(0);
    const unknownVoters = ref([]);
    const triggerFileUpload = () => {
      if (cityId.value === 0 || !cityId.value) {
        toastService.error("Please select a city or municipality first");
        return;
      }
      fileInput.value.click();
    };

    const downloadVoterList = () => {
      // CSV Export
      spinnerService.show();
      const worksheet = XLSX.utils.json_to_sheet(
        unknownVoters.value.map((voter) => ({ Name: voter.name_voter }))
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Unknown Voters");
      XLSX.writeFile(workbook, `unknown_voters${crypto.randomUUID()}.xlsx`);
      spinnerService.hide();
    };

    const handleFileUpload = async (event) => {
      successMessage.value = null;
      uploadedRows.value = 0;
      const file = event.target.files[0];
      if (!file) return;

      isProcessing.value = true;
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets["Supporters"], {
          header: 1,
        });

        const headers = worksheet[0];
        const rows = worksheet.slice(1);
        totalRows.value = rows.length;
        console.log(totalRows.value, sheetName);
        // Validate required fields
        //this.errors = this.requiredFields.filter((field) => !headers.includes(field));
        if (errors.value.length > 0) {
          isProcessing.value = false;
          return;
        }

        const voterNameIndex = headers.indexOf("name_voter");
        //const statusIndex = headers.indexOf("STATUS");
        const brgyIndex = headers.indexOf("brgyid_voter");
        const sitioIndex = headers.indexOf("sitio_voter");
        const remarksIndex = headers.indexOf("remarks_voter");
        const pleaderIndex = headers.indexOf("pleader_voter");
        const leaderIndex = headers.indexOf("leader_voter");
        const subleaderIndex = headers.indexOf("subleader_voter");
        const typeIndex = headers.indexOf("type_voter");
        //const positionIndex = headers.indexOf("POSITION");
        const precinctIndex = headers.indexOf("precinct_voter");
        if (voterNameIndex === -1) {
          // Handle case where "VOTER'S NAME" column is not found
          toastService.error("VOTER'S NAME column not found");
          isProcessing.value = false;
          return;
        }
        if (voterNameIndex === -1) {
          // Handle case where "VOTER'S NAME" column is not found
          toastService.error("Supporter column not found");
          isProcessing.value = false;
          return;
        }
        spinnerService.show();
        const transactionId = crypto.randomUUID();
        const dataToSend = rows.map((row) => {
          const obj = {};
          const name = row[voterNameIndex]?.trim();
          /* if (name && !/\b(JR\.|SR\.)\b/i.test(name)) {
            obj["name_voter"] = name.replace(/\./g, "");
          } else {
           
          }*/
          obj["name_voter"] = name;
          obj["remarks_voter"] = String(row[remarksIndex] ?? "");

          if (row[precinctIndex] && row[precinctIndex] === "NOT FOUND") {
            obj["remarks_voter"] = (obj["remarks_voter"] + " NOT FOUND").trim();
          }
          obj["cityid_voter"] = cityId.value;
          obj["side_voter"] = 1;
          obj["brgyid_voter"] = Number(row[brgyIndex] ?? 0);
          obj["pleader_voter"] = String(row[pleaderIndex] ?? null);
          obj["leader_voter"] = String(row[leaderIndex] ?? null);
          obj["subleader_voter"] = String(row[subleaderIndex] ?? null);
          //obj["loyaltystate_voter"] = row[statusIndex] ?? "";
          //obj["kagawad_voter"] = String(row[positionIndex] ?? null);
          obj["sitio_voter"] = String(row[sitioIndex] ?? "");
          obj["type_voter"] = String(row[typeIndex] ?? null);
          return obj;
        });

        // Process in batches of 300 rows
        const batchSize = 300;
        const totalPages = Math.ceil(dataToSend.length / batchSize);

        // Process in batches of 20 rows
        for (let i = 0; i < dataToSend.length; i += batchSize) {
          const currentPage = Math.floor(i / batchSize) + 1;
          const batch = dataToSend.slice(i, i + batchSize);

          // Optional: You can log or use currentPage and totalPages as needed
          console.log(`Processing page ${currentPage} of ${totalPages}`);

          uploadedRows.value += batch.length;
          await sendBatchToApi(batch, currentPage, totalPages, transactionId);
          updateProgress();
        }
        console.log(dataToSend.length);
        spinnerService.hide();
        successMessage.value = "Data processed and sent successfully!";
        isProcessing.value = false;
      };

      reader.readAsArrayBuffer(file);
    };

    const sendBatchToApi = async (batch, page, total, transId) => {
      const maxRetries = 15;
      let attempts = 0;
      let success = false;

      while (attempts < maxRetries && !success) {
        try {
          console.log(`Attempt ${attempts + 1} to send batch:`, batch);
          const response = await voterService.importSupporters(
            batch,
            page,
            total,
            transId,
            cityId.value
          );
          if (!response.success) throw "Failed";
          // Handle response as needed
          // If the request is successful, set success to true to break out of the loop
          success = true;
          console.log("Batch sent successfully");
          if (!response.hasNextAction) {
            unknownVoters.value = response.unknownVoters;
          }
        } catch (error) {
          attempts += 1;
          console.error(`Error sending batch to API (Attempt ${attempts}):`, error);

          // If the maximum number of retries is reached, notify the user
          if (attempts === maxRetries) {
            console.error("Max retries reached. Unable to send batch to API.");
            // You can add additional user notification logic here if needed
          } else {
            // Optionally, wait a bit before retrying (e.g., 1 second delay)
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }
      }
    };

    const updateProgress = () => {
      const progress = (uploadedRows.value / totalRows.value) * 100;
      const dashArrayValue = `${progress} ${100 - progress}`;
      progressDasharray.value = dashArrayValue;
    };

    onMounted(async () => {
      cities.value = await areaService.getMunicipalities();
    });

    return {
      errors,
      successMessage,
      isProcessing,
      uploadedRows,
      totalRows,
      handleFileUpload,
      progressDasharray,
      fileInput,
      triggerFileUpload,
      cities,
      cityId,
      unknownVoters,
      downloadVoterList,
    };
  },
};
</script>

<style scoped>
.file-upload-container {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.errors-container,
.success-container {
  margin-top: 20px;
}

.progress-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.bi-file-earmark-spreadsheet {
  font-size: 50px;
  color: #28a745;
}

.progress-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top: 5px solid #28a745;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
