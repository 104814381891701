<template>
  <div class="row">
    <h3 class="d-flex align-items-center">
      <i class="bi bi-flag-fill display-5 me-2"></i> Reports
    </h3>
    <form @submit.prevent="searchReports">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>NAME</th>
              <th>REPORTED SIDE</th>
              <th>CURRENT SIDE</th>
              <th>REPORTED BY</th>
              <th>REMARKS</th>
              <th>DATE</th>
            </tr>
            <tr>
              <th>
                <input v-model="searchModel.name_voter" class="form-control" />
              </th>
              <th>
                <input v-model="searchModel.side_mrts" class="form-control" />
              </th>
              <th>
                <input v-model="searchModel.side_voter" class="form-control" />
              </th>
              <th>
                <input v-model="searchModel.name_user" class="form-control" />
              </th>
              <th>
                <input v-model="searchModel.remarks_mrts" class="form-control" />
              </th>
              <th>
                <input v-model="searchModel.date_mrts" class="form-control" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              @click="handleClick(r)"
              style="cursor: pointer"
              v-for="r in reports"
              :key="r.syspk_mrts"
            >
              <td>{{ r.name_voter }}</td>
              <td>
                <h3>
                  <i class="bi bi-check text-success" v-if="r.side_mrts"></i>
                  <i class="bi bi-x text-danger" v-else></i>
                </h3>
              </td>
              <td>
                <h3>
                  <i class="bi bi-check text-success" v-if="r.side_voter"></i>
                  <i class="bi bi-x text-danger" v-else></i>
                </h3>
              </td>
              <td>{{ r.name_user }}</td>
              <td>{{ r.remarks_mrts }}</td>
              <td>{{ r.timeago }}</td>
            </tr>
            <tr v-if="reports === null || reports.length === 0">
              <td colspan="6" class="text-center">No records found.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button type="submit" class="d-none" />
    </form>

    <PaginationView
      v-if="reports !== null && reports.lengtd !== 0"
      @page-change="handleChange"
      :page="searchModel.currentPage"
      :pages="searchModel.totalPages"
      :items="searchModel.totalItems"
    />
    <FullscreenModal title="Voter Information" @close="onClose" :show="showModal">
      <VoterInfo
        :id="currentId"
        :showModal="showModal"
        @next="handleNext"
        @prev="handlePrev"
        @reset="onReset"
        @voter-saved="onVoterSaved"
      />
    </FullscreenModal>
  </div>
</template>

<script setup>
import spinnerService from "@/services/spinnerService";
import voterService from "@/services/voterService";
import FullscreenModal from "@/components/modals/FullscreenModal.vue";
import VoterInfo from "@/components/voters/VoterInfo.vue";
import PaginationView from "@/components/PaginationControl.vue";
import { onMounted, ref } from "vue";

const currentId = ref(0);
const showModal = ref(false);
const reports = ref(null);
const searchModel = ref({ currentPage: 1, totalPages: 1, totalItems: 0 });
const onClose = () => {
  currentId.value = 0;
  toggleModal();
};
const toggleModal = () => {
  showModal.value = !showModal.value;
};

const handleChange = async (page) => {
  spinnerService.show();
  searchModel.value.currentPage = page;
  const resp = await voterService.searchReports(page, searchModel.value);
  reports.value = resp.reports;
  searchModel.value.totalPages = resp.totalPages;
  searchModel.value.totalItems = resp.totalItems;
  spinnerService.hide();
};

const handleClick = (r) => {
  currentId.value = r.sysfk_voter_mrts;
  toggleModal();
};

const onVoterSaved = () => {
  toggleModal();
};

const searchReports = async () => {
  spinnerService.show();
  searchModel.value.currentPage = 1;
  const resp = await voterService.searchReports(1, searchModel.value);
  reports.value = resp.reports;
  searchModel.value.totalPages = resp.totalPages;
  searchModel.value.totalItems = resp.totalItems;
  spinnerService.hide();
};

onMounted(() => {
  searchReports();
});
</script>
