import DOMPurify from "dompurify";
const customizationService = {
    getPLeaderLabel(cityid) {
        switch (cityid) {
          case 4:
            return "BOMTE";
          case 3:
            return "BCC";
          default:
            return "PRINCIPAL";
        }
      },
  
      getLeaderLabel (cityid){
        switch (cityid) {
          case 4:
            return "POMTE";
          case 3:
            return "PUROK LEADER";
          default:
            return "LEADER";
        }
      },
  
      getSubLeaderLabel(cityid) {
        switch (cityid) {
          case 4:
            return "A1";
          case 3:
            return "FAMILY LEADER";
          default:
            return "SUB-LEADER";
        }
      },
      highlightMatchingText(source,text) {
        if (!source || !text) return text;
        const regex = new RegExp(`(${source})`, "gi");
        // Replace matching text with <strong> tags
        const highlightedText = text.replace(regex, "<strong>$1</strong>");
        return DOMPurify.sanitize(highlightedText);
      }
}

export default  customizationService;