<template>
  <form @submit.prevent="handleSearch">
    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th>Title</th>
            <th>Mode</th>
            <th>Content</th>
            <th>Created</th>
          </tr>
          <tr>
            <th>
              <input class="form-control" v-model="searchOptions.header_voteractlog" />
            </th>
            <th>
              <input class="form-control" v-model="searchOptions.mode_voteractlog" />
            </th>
            <th>
              <input class="form-control" v-model="searchOptions.content_voteractlog" />
            </th>
            <th>
              <input class="form-control" v-model="searchOptions.created_voteractlog" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in logs" :key="log.id_voteractlog">
            <td v-html="log.header_voteractlog"></td>
            <td>{{ log.mode_voteractlog }}</td>
            <td>{{ log.content_voteractlog }}</td>
            <td>{{ log.created_voteractlog }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button type="submit" class="d-none"></button>
  </form>
  <PaginationControl
    @page-change="onPageChanged"
    :page="p.currentPage"
    :items="p.totalItems"
    :pages="p.totalPages"
  />
</template>
<script>
import { onMounted, ref } from "vue";
import PaginationControl from "../PaginationControl.vue";
import voterService from "@/services/voterService";
import spinnerService from "@/services/spinnerService";

export default {
  components: {
    PaginationControl,
  },
  setup() {
    const searchOptions = ref({});
    const paginationSearchOptions = ref({});
    const logs = ref([]);
    const p = ref({ currentPage: 1, totalItems: 0, totalPages: 1 });
    const handleSearch = () => {
      paginationSearchOptions.value = { ...searchOptions.value };
      searchLogs(1, searchOptions.value);
    };

    const searchLogs = async (page, query) => {
      spinnerService.show();
      const response = await voterService.searchLogs(page, query);
      logs.value = response.logs;
      p.value.totalItems = response.totalItems;
      p.value.totalPages = response.totalPages;
      p.value.currentPage = page;
      spinnerService.hide();
    };

    const onPageChanged = async (page) => {
      await searchLogs(page, paginationSearchOptions.value);
      p.value.currentPage = page;
    };

    onMounted(() => {
      searchLogs(1, searchOptions.value);
    });
    return {
      searchOptions,
      onPageChanged,
      handleSearch,
      logs,
      p,
    };
  },
};
</script>
