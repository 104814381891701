<template>
  <div v-if="hasRequiredRole(['admin', 'web-dashboard'])">
    <div class="progress-border-container bg-light">
      <div class="progress-bar" :style="{ width: progressValue + '%' }"></div>
    </div>
    <select @change="handleChange" class="form-select my-2" v-model="currentCityId">
      <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
        {{ city.name_mun }}
      </option>
    </select>
    <div class="row pt-2">
      <div class="col-lg-9">
        <DashboardCards
          :id="currentCityId"
          @win="dropTheConfetti"
          @progress-updated="updateProgressBar"
        />

        <DashboardBrgysTable :id="currentCityId" />
      </div>
      <div class="col-lg-3">
        <DashboardOnlineUsers
          :id="currentCityId"
          :totalOnline="onlineUserCount"
          :previewUsers="previewUsers"
          @user-clicked="onUserClicked"
        />
        <DashboardRecentLogs :id="currentCityId" />
      </div>
    </div>

    <MediumSizedModal :title="modalTitle" :show="showModal" @close="toggleModal">
      <div class="card">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div>
            <h5 class="card-title mb-1">{{ currentusername }}</h5>
            <p class="card-text mb-0">
              Last seen: {{ formatRelativeTime(currentUserLocation.timestamp_loc) }}
            </p>
          </div>
          <button class="btn btn-primary btn-locate" @click.prevent="locateUser">
            <i class="bi bi-geo-alt-fill"></i> Locate
          </button>
        </div>
      </div>
    </MediumSizedModal>
  </div>
  <div v-else class="d-flex justify-content-center align-items-center vh-100">
    <div class="card text-center shadow">
      <div class="card-body">
        <i class="bi bi-lock-fill text-danger" style="font-size: 3rem"></i>
        <h5 class="text-muted mt-3">Contents Are Hidden</h5>
        <p class="mt-2">
          You do not have the necessary role or rights to view this content. Please
          contact your administrator for assistance.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardBrgysTable from "@/components/dashboard/DashboardBrgysTable.vue";
import DashboardCards from "@/components/dashboard/DashboardCards.vue";
import DashboardOnlineUsers from "@/components/dashboard/DashboardOnlineUsers.vue";
import DashboardRecentLogs from "@/components/dashboard/DashboardRecentLogs.vue";
import MediumSizedModal from "@/components/modals/MediumSizedModal.vue";
import areaService from "@/services/areaService";
import signalRService from "@/services/signalRService";
import userService from "@/services/userService";
import { formatDistanceToNow } from "date-fns";
import { onMounted, onUnmounted, ref } from "vue";
import { getUserRoles, hasRoles } from "@/utils/auth";

import confetti from "canvas-confetti";

export default {
  components: {
    DashboardCards,
    DashboardOnlineUsers,
    DashboardRecentLogs,
    DashboardBrgysTable,
    MediumSizedModal,
  },
  setup() {
    const onlineUserCount = ref(0);
    const previewUsers = ref([]);
    const currentCityId = ref(0);
    const cities = ref([]);
    const progressValue = ref(0);
    const showModal = ref(false);

    const currentusername = ref("");
    const currentUserLocation = ref({ syspk_loc: 0, timestamp_loc: new Date() });
    const isGettingLocation = ref(false);
    const modalTitle = ref("User Location");
    const toggleModal = () => {
      showModal.value = !showModal.value;
    };
    const handleChange = () => {
      localStorage.setItem("user-defaultcity", currentCityId.value);
    };
    const formatRelativeTime = (timestamp) => {
      return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    };
    const dropTheConfetti = () => {
      let count = 0;
      const maxCount = 10; // Trigger confetti 10 times

      const triggerConfetti = () => {
        if (count < maxCount) {
          count++;

          // Create confetti burst from random positions
          confetti({
            particleCount: 100,
            spread: 360,
            startVelocity: 30,
            origin: { x: Math.random(), y: Math.random() - 0.2 }, // Random x and y positions
          });

          // Call confetti again after 500ms (or adjust as needed)
          setTimeout(triggerConfetti, 500);
        }
      };

      // Start the confetti loop
      triggerConfetti();
    };

    onMounted(async () => {
      //dropTheConfetti();
      /* Chart.register(
        LineController,
        LineElement,
        PointElement,
        CategoryScale,
        LinearScale
      );
       const ctx = document.getElementById("voterMovementChart").getContext("2d");
       const myChart = new Chart(ctx, {
        type: "line", // Corrected chart type
        data: performanceData, // Ensure barChartData contains labels and datasets
      });*/
      // dropTheConfetti();
      signalRService.subscribeToEvent("UserCountUpdated", handleMessage);
      cities.value = await areaService.getMunicipalities();

      const citystorage = localStorage.getItem("user-defaultcity");
      if (citystorage) {
        currentCityId.value = Number(citystorage);
      } else {
        if (cities.value.length === 1) {
          currentCityId.value = cities.value[0].id_mun;
        }
      }
      fetchUsers(currentCityId.value);
      //myChart;
    });
    const handleMessage = async (userCount) => {
      onlineUserCount.value = userCount;
      await fetchUsers(currentCityId.value);
    };
    const fetchUsers = async (id) => {
      try {
        const response = await userService.getPaginatedUsers(id, 1, 5);
        previewUsers.value = response.users;
        onlineUserCount.value = response.totalCount;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const updateProgressBar = (pVal) => {
      progressValue.value = (pVal * 100).toFixed(2);
    };

    const performanceData = {
      labels: ["January", "February", "March", "April", "May", "June"], // Time periods
      datasets: [
        {
          label: "Actual Performance", // Label for actual performance
          data: [50, 60, 55, 70, 65, 80], // Actual performance values
          borderColor: "rgba(75, 192, 192, 1)", // Line color
          backgroundColor: "rgba(75, 192, 192, 0.2)", // Optional fill color for area under the line
          fill: false, // Don't fill under the line
          tension: 0.1, // Curve the line slightly
        },
        {
          label: "Target", // Label for the target values
          data: [100, 100, 100, 100, 100, 100], // Target values to meet each month
          borderColor: "rgba(255, 99, 132, 1)", // Color of the target line
          borderDash: [5, 5], // Dashed line to distinguish target line
          fill: false, // Don't fill under the line
          tension: 0.1, // Curve the line slightly
        },
      ],
    };
    const barChartData = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Bar Dataset",
          backgroundColor: "#42A5F5",
          data: [40, 20, 12, 39, 10, 40, 39],
        },
      ],
    };

    const lineChartData = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Line Dataset",
          borderColor: "#FF6384",
          backgroundColor: "#FF6384",
          data: [20, 10, 30, 50, 20, 80, 30],
        },
      ],
    };

    const pieChartData = {
      labels: ["Red", "Blue", "Yellow"],
      datasets: [
        {
          label: "Pie Dataset",
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          data: [300, 50, 100],
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
    };

    const onUserClicked = async (user) => {
      modalTitle.value = `User Location`;
      currentusername.value = user.userName;
      const requiredRoles = ["admin", "locate-user"];
      if (getUserRoles().some((role) => requiredRoles.includes(role))) {
        toggleModal();
        currentUserLocation.value = await userService.getLastKnownLocation(user.userId);
      } else {
        return;
      }
    };

    const hasRequiredRole = (requiredRoles) => {
      return hasRoles(requiredRoles);
    };

    const locateUser = () => {
      const url = `https://www.google.com/maps?q=${currentUserLocation.value.lat_loc},${currentUserLocation.value.lng_loc}`;
      // Open the URL in a new tab
      window.open(url, "_blank");
    };
    // Clean up subscriptions when component unmounts
    onUnmounted(() => {
      signalRService.connection.off("ReceiveMessage", handleMessage);
    });

    return {
      onlineUserCount,
      previewUsers,
      currentCityId,
      onUserClicked,
      modalTitle,
      toggleModal,
      showModal,
      isGettingLocation,
      locateUser,
      formatRelativeTime,
      currentUserLocation,
      currentusername,
      cities,
      handleChange,
      barChartData,
      lineChartData,
      pieChartData,
      chartOptions,
      performanceData,
      dropTheConfetti,
      updateProgressBar,
      progressValue,
      hasRequiredRole,
    };
  },
};
</script>
<style scoped>
.progress-border-container {
  width: 100%;
  height: 3px; /* Set the height of the top border */
  background-color: transparent; /* Light background for the border */
  position: fixed; /* Make it stick to the top of the screen */
  top: 0;
  /*margin-top: 70px;*/
  z-index: 99999999999999;
  left: 0;
}

/* Progress bar styling */
.progress-bar {
  height: 100%;
  background-color: #fd0707; /* Color of the progress */
  transition: width 0.4s ease; /* Smooth transition effect */
}
</style>
