<template>
  <h3><i class="bi bi-clock-history me-1"></i> Activation Logs</h3>
  <form @submit.prevent="handleSearch">
    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th>Mobile No</th>
            <th>Code</th>
            <th>Status</th>
            <th>Message</th>
          </tr>
          <tr>
            <th>
              <input
                v-model="ulogSearchParams.mobileno_uv"
                class="form-control"
                type="text"
              />
            </th>
            <th>
              <input
                v-model="ulogSearchParams.userpk_uv"
                class="form-control"
                type="text"
              />
            </th>
            <th>
              <input
                v-model="ulogSearchParams.state_uv"
                class="form-control"
                type="text"
              />
            </th>
            <th>
              <input
                v-model="ulogSearchParams.message_uv"
                class="form-control"
                type="text"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ulog in userlogs" :key="ulog.syspk_ulog">
            <td>{{ ulog.mobileno_uv }}</td>
            <td>{{ ulog.userpk_uv }}</td>
            <td>
              <h4>
                <i v-if="ulog.state_uv === 1" class="bi bi-check text-success"></i>
                <i v-else class="bi bi-x text-danger"></i>
              </h4>
            </td>
            <td>{{ ulog.message_uv }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button type="submit" class="d-none"></button>
  </form>
  <PaginationControl
    @page-change="onPageChanged"
    :page="p.currentPage"
    :items="p.totalItems"
    :pages="p.totalPages"
  />
</template>

<script setup>
import spinnerService from "@/services/spinnerService";
import userService from "@/services/userService";
import { onMounted, ref } from "vue";
import PaginationControl from "@/components/PaginationControl.vue";
const p = ref({ currentPage: 1, totalItems: 0, totalPages: 1 });
const userlogs = ref([]);
const ulogSearchParams = ref({});
const paginationSearchOptions = ref({});
const handleSearch = async () => {
  searchLogs(1, ulogSearchParams.value);
};

const searchLogs = async (page, params) => {
  spinnerService.show();
  const resp = await userService.searchActivationLogs(page, params);
  userlogs.value = resp.userVerifications;
  p.value.totalItems = resp.totalItems;
  p.value.totalPages = resp.totalPages;
  p.value.currentPage = page;
  spinnerService.hide();
};

const onPageChanged = async (page) => {
  paginationSearchOptions.value = { ...ulogSearchParams.value };
  await searchLogs(page, paginationSearchOptions.value);
  p.value.currentPage = page;
};
onMounted(() => {
  handleSearch();
});
</script>
