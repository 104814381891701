<template>
  <h3><i class="bi bi-clock-history me-1"></i> Log Information</h3>
  <div class="log-entry card mb-3 p-3">
    <div class="card-body" v-if="!isProcessing">
      <div class="log-header d-flex justify-content-between">
        <span class="fw-bold text-primary" v-html="currentLog.header_voteractlog"></span>
        <span class="fw-bold text-success">{{ currentLog.mode_voteractlog }}</span>
      </div>
      <div class="log-action mt-2">
        <p class="text-dark">{{ currentLog.content_voteractlog }}</p>
      </div>

      <div class="log-time mt-3">
        <span class="text-muted">{{ currentLog.created_voteractlog }}</span>
      </div>
    </div>
    <div v-else class="text-center">
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import voterService from "@/services/voterService";
import { onMounted, ref } from "vue";

export default {
  props: {
    id: { type: String, require: true },
  },
  setup(props) {
    const currentLog = ref({});
    const isProcessing = ref(false);
    const getLogInfo = async (id) => {
      isProcessing.value = true;
      const resp = await voterService.getLogInfo(id);
      currentLog.value = resp;
      isProcessing.value = false;
    };

    onMounted(() => {
      getLogInfo(props.id);
    });

    return {
      props,
      currentLog,
      isProcessing,
    };
  },
};
</script>
