<template>
  <div class="row">
    <form @submit.prevent="handleSubmit">
      <div class="form-check form-switch">
        <!-- Toggle switch -->
        <input
          @change="handleCityChange"
          class="form-check-input"
          type="checkbox"
          id="sitioSwitch"
          v-model="isSitioSetup"
        />
        <label class="form-check-label" for="sitioSwitch">
          {{ isSitioSetup ? "For Sitio" : "For Brgy" }}
        </label>
      </div>
      <div class="form-group">
        <label>City/Municipality</label>
        <select
          required
          @change="handleCityChange"
          v-model="polygonInfo.id_mun"
          class="form-select"
        >
          <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
            {{ city.name_mun }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Brgy</label>
        <select
          required
          v-model="polygonInfo.id_brgy"
          class="form-select"
          @change="handleBrgyChange"
        >
          <option v-for="brgy in brgys" :key="brgy.id_brgy" :value="brgy.id_brgy">
            {{ brgy.name_brgy }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label>Sitio/Purok</label>
        <select v-model="polygonInfo.id_sitio" class="form-select">
          <option v-for="sitio in sitios" :key="sitio.id_sitio" :value="sitio.id_sitio">
            {{ sitio.name_sitio }}
          </option>
        </select>
      </div>
      <div class="form-group mt-3">
        <button type="submit" class="btn btn-success w-100">Save Changes</button>
      </div>
    </form>
  </div>
</template>
<script>
import areaService from "@/services/areaService";
import toastService from "@/services/toastService";
import { onMounted, ref, watch } from "vue";

export default {
  props: {
    refreshInvoked: Boolean,
  },
  emits: ["polygon-saved"],
  setup(props, { emit }) {
    const polygonInfo = ref({ id_mun: 0, id_brgy: 0, id_sitio: 0 });
    const cities = ref([]);
    const brgys = ref([]);
    const sitios = ref([]);
    const isSitioSetup = ref(false);

    const getPresets = async () => {
      cities.value = await areaService.getMunicipalities();
      brgys.value = [];
    };

    const handleCityChange = async () => {
      if (!isSitioSetup.value)
        brgys.value = await areaService.getBrgysWithNoPolygon(polygonInfo.value.id_mun);
      else brgys.value = await areaService.getBrgysWithPolygons(polygonInfo.value.id_mun);
    };

    const handleBrgyChange = async () => {
      sitios.value = await areaService.getSitioWithNoPolygons(polygonInfo.value.id_brgy);
    };

    const handleSubmit = () => {
      if (!isSitioSetup.value) {
        if (polygonInfo.value.id_mun === 0 || polygonInfo.value.id_brgy === 0) {
          toastService.error("Please select valid values.");
          return;
        }
      } else {
        toastService.error("Please select valid values.");
        if (polygonInfo.value.id_sitio === 0) return;
      }
      polygonInfo.value.type = isSitioSetup.value ? "sitio" : "brgy";
      emit("polygon-saved", polygonInfo.value);
    };
    onMounted(() => {
      getPresets();
    });

    watch(
      () => props.refreshInvoked,
      () => {
        getPresets();
      }
    );

    return {
      handleCityChange,
      handleSubmit,
      cities,
      brgys,
      polygonInfo,
      sitios,
      handleBrgyChange,
      isSitioSetup,
    };
  },
};
</script>
