import DashboardPage from '@/pages/DashboardPage.vue';
import VotersPage from '@/pages/voters/VotersPage.vue';
import LoginPage from '@/pages/user/LoginPage.vue';
import NotFound from '@/pages/NotFound.vue';
import { getUserRoles, isAuthenticated } from '@/utils/auth';
import { createRouter, createWebHistory } from 'vue-router';
import { roles } from '@/utils/config';
import HouseholdsPage from '@/pages/households/HouseholdsPage.vue';
import ForbiddenPage from '@/pages/ForbiddenPage.vue';
import HeatMapPage from '@/pages/maps/HeatMapPage.vue';
import HeatMapSetupPage from '@/pages/maps/HeatMapSetupPage.vue';
import BarangaySettings from '@/pages/areas/BarangaySettings.vue';
import CitySettings from '@/pages/areas/CitySettings.vue';
import UserSetup from '@/pages/user/UserSetup.vue';
import IDCardPrintingPage from '@/pages/printing/IDCardPrintingPage.vue';
import EventsPage from '@/pages/logs/EventsPage.vue';
import store from '@/store';
import ImportPage from '@/pages/voters/ImportPage.vue';
import ActionsPage from '@/pages/actions/ActionsPage.vue';
import UserLogsPage from '@/pages/user/UserLogsPage.vue';
import ActivationLogsPage from '@/pages/user/ActivationLogsPage.vue';
import VotersMaritess from '@/pages/voters/VotersMaritess.vue';
import LogDetailPage from '@/pages/voters/LogDetailPage.vue';
const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: DashboardPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminDashboardRoles }
    },
    {
        path: '/voters',
        name: 'voters',
        component: VotersPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminVotersRoles }
    },
    {
        path: '/households',
        name: 'households',
        component: HouseholdsPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminHouseholdRoles }
    },
    {
        path: '/heatmap',
        name: 'heatmap',
        component: HeatMapPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminHeatMapRoles }
    },
    {
        path: '/map-setup',
        name: 'map-setup',
        component: HeatMapSetupPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminHeatMapSetup }
    },
    {
        path: '/brgy-setup',
        name: 'brgy-setup',
        component: BarangaySettings,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminBarangaySettings }
    },
    {
        path: '/city-setup',
        name: 'city-setup',
        component: CitySettings,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminCitySettings }
    },
    {
        path: '/user-setup',
        name: 'user-setup',
        component: UserSetup,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminUserSettings }
    },
    {
        path: '/user-actions',
        name: 'user-actions',
        component: ActionsPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'user-actions'] }
    },
    {
        path: '/print-id',
        name: 'print-id',
        component: IDCardPrintingPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminPrint }
    },
    {
        path: '/voter-logs',
        name: 'voter-logs',
        component: EventsPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminLogs }
    },
    {
        path: '/voter-maritess',
        name: 'voter-maritess',
        component: VotersMaritess,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin','web-voter-maritess'] }
    },
    {
        path: '/user-logs',
        name: 'user-logs',
        component: UserLogsPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'web-user-logs'] },
    },
    {
        path: '/log-details/:id',
        name: 'log-details',
        component: LogDetailPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'web-user-logs'] },
        props: true

    },
    {
        path: '/user-verification',
        name: 'user-verification',
        component: ActivationLogsPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'user-verification'] }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: { showHeader: false }
    },
    {
        path: '/imports',
        name: 'imports',
        component: ImportPage,
        meta: { showHeader: true, requiresAuth: true, roles: roles.adminImport }
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: ForbiddenPage,
        meta: { showHeader: false }
    },
    { path: '/:catchAll(.*)', component: NotFound, meta: { showHeader: false } }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash };
        }
        return savedPosition || { top: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
    // Check if the route requires authentication and the user is authenticated
    if (to.meta.requiresAuth && !isAuthenticated()) {
        // Redirect to login page with return URL as query parameter
        return next({
            path: '/login',
            query: { return_url: to.fullPath }
        });
    }

    // Handle role-based access control
    if (to.meta.roles && to.meta.roles.length > 0) {
        const userRoles = getUserRoles(); // Example function to get user roles
        const allowedRoles = to.meta.roles;

        // Check if any of the user's roles match the allowed roles
        const hasAccess = userRoles.some(role => allowedRoles.includes(role));

        if (!hasAccess) {
            // Redirect to unauthorized page or handle unauthorized access
            return next('/forbidden'); // Example redirect to unauthorized page
        }
    }

    // Update header visibility based on the route meta
    const showHeader = to.meta.showHeader !== false; // Default to true if not explicitly set
    store.dispatch("showAdminHeader", showHeader);
    // Continue to the route
    next();
});


export default router;
