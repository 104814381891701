<template>
  <div v-if="!isProcessing" class="row">
    <!-- Total Population Card -->
    <div class="col-12 col-md-6 mb-3">
      <div class="card text-white bg-danger">
        <div class="card-body">
          <h5 class="card-title"><i class="bi bi-person-fill"></i> Total Supporters</h5>
          <p class="card-text fw-bold">
            {{ totalPopulation || 0 }} /
            {{
              ((totalPopulation / (totalPopulation + nonSupporters)) * 100 || 0).toFixed(
                2
              )
            }}%
          </p>
        </div>
      </div>
    </div>

    <!-- Non-Supporters Card -->
    <div class="col-12 col-md-6 mb-3">
      <div class="card text-white bg-primary">
        <div class="card-body">
          <h5 class="card-title"><i class="bi bi-person-x-fill"></i> Non-Supporters</h5>
          <p class="card-text fw-bold">{{ nonSupporters || 0 }}</p>
        </div>
      </div>
    </div>

    <!-- Difference Card -->
    <div class="col-12 col-md-6 mb-3">
      <div class="card text-white bg-info">
        <div class="card-body">
          <h5 class="card-title"><i class="bi bi-bar-chart-line"></i> Difference</h5>
          <p class="card-text fw-bold">{{ difference }}</p>
        </div>
      </div>
    </div>

    <!-- Additional Information Card -->
    <div class="col-12 col-md-6 mb-3">
      <div class="card text-white bg-success">
        <div class="card-body">
          <h5 class="card-title"><i class="bi bi-info-circle"></i> Expected Turnout</h5>
          <p class="card-text fw-bold">{{ additionalInfo }}%</p>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <div class="spinner-container">
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import areaService from "@/services/areaService";
import { onMounted, ref, watch } from "vue";

export default {
  props: {
    id: Number,
    type: String,
  },
  setup(props) {
    const totalPopulation = ref(100);
    const nonSupporters = ref(100);
    const difference = ref(100);
    const additionalInfo = ref("80%");
    const isProcessing = ref(false);
    const getMunInfo = async (id) => {
      if (id === 0) return;
      isProcessing.value = true;
      const response = await areaService.getMunicipalAreaVoterSummary(id);
      nonSupporters.value = response.totalNonSupporters;
      totalPopulation.value = response.totalSupporters;
      difference.value = Number(totalPopulation.value) - Number(nonSupporters.value);
      additionalInfo.value = (
        ((Number(totalPopulation.value) + Number(nonSupporters.value)) /
          Number(response.totalRawVoters)) *
        100
      ).toFixed(2);
      isProcessing.value = false;
    };
    const getBrgyInfo = async (id) => {
      if (id === 0) return;
      isProcessing.value = true;
      const response = await areaService.getBrgyAreaVoterSummary(id);
      nonSupporters.value = response.totalNonSupporters;
      totalPopulation.value = response.totalSupporters;
      difference.value = response.totalSupporters - response.totalNonSupporters;
      additionalInfo.value = (response.expectedTurnout * 100).toFixed(2);
      isProcessing.value = false;
    };

    const getSitioInfo = async (id) => {
      if (id === 0) return;
      isProcessing.value = true;
      const response = await areaService.getSitioAreaVoterSummary(id);
      nonSupporters.value = response.totalNonSupporters;
      totalPopulation.value = response.totalSupporters;
      difference.value = response.totalSupporters - response.totalNonSupporters;
      additionalInfo.value = (response.expectedTurnout * 100).toFixed(2);
      isProcessing.value = false;
    };
    watch(
      () => props.id,
      (newVal, oldVal) => {
        if (newVal != oldVal) {
          if (props.type === "mun") getMunInfo(newVal);
          else if (props.type === "brgy") getBrgyInfo(newVal);
          else {
            getSitioInfo(newVal);
          }
        }
      }
    );
    onMounted(() => {
      if (props.type === "mun") getMunInfo(props.id);
      else if (props.type === "brgy") getBrgyInfo(props.id);
      else getSitioInfo(props.id);
    });
    return {
      totalPopulation,
      nonSupporters,
      difference,
      additionalInfo,
      isProcessing,
    };
  },
};
</script>
