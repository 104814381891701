<template>
  <div class="text-end">
    <button
      v-if="hasRequiredRole(roles.adminAddUser)"
      @click.prevent="handleAddUser"
      class="btn btn-sm btn-success"
    >
      <i class="bi bi-plus"></i>
    </button>
  </div>
  <form @submit.prevent="handleSearch">
    <div class="table-responsive"></div>
    <table class="table align-middle">
      <thead>
        <tr>
          <th>Username</th>
          <th>Fullname</th>
          <th>City</th>
          <th>Barangays</th>
          <th>Mobile No</th>
          <th>Verified</th>
          <th>Enabled</th>
        </tr>
        <tr>
          <th>
            <input class="form-control" v-model="searchModel.name_user" />
          </th>
          <th>
            <input class="form-control" v-model="searchModel.fullname_user" />
          </th>
          <th>
            <select
              class="form-select"
              @change="handleSearch"
              v-model="searchModel.cityid_user"
            >
              <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
                {{ city.name_mun }}
              </option>
            </select>
          </th>
          <th>
            <input class="form-control" v-model="searchModel.assignedBarangays" />
          </th>
          <th>
            <input class="form-control" v-model="searchModel.mobileno_user" />
          </th>
          <th>
            <input class="form-control" v-model="searchModel.isverified_user" />
          </th>
          <th>
            <input class="form-control" v-model="searchModel.isenabled" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{ 'table-danger': user.isenabled !== 1 }"
          v-for="user in users"
          :key="user.syspk_user"
        >
          <td>
            <a
              @click.prevent="handleItemClick(user)"
              class="text-decoration-none text-dark"
              href="#"
              ><i class="bi bi-pencil"></i>{{ user.name_user }}</a
            >
          </td>
          <td>
            {{ user.fullname_user }}
          </td>
          <td>
            {{ user.name_mun }}
          </td>
          <td>
            <span
              v-if="searchModel.assignedBarangays"
              v-html="highlightMatchingText(user.assignedBarangays)"
            ></span>
            <span v-else>{{ user.assignedBarangays }} </span>
          </td>
          <td>
            {{ user.mobileno_user }}
          </td>

          <td class="text-center">
            <a href="#" @click.prevent="handleToggleUserVerified(user)">
              <i
                class="bi bi-toggle-on text-success fs-2"
                v-if="user.isverified_user === 1"
              ></i>
              <i class="bi bi-toggle-off text-secondary fs-2" v-else></i>
            </a>
          </td>
          <td class="text-center">
            <a href="#" @click.prevent="handleToggleUser(user)">
              <i
                class="bi bi-toggle-on text-success fs-2"
                v-if="user.isenabled === 1"
              ></i>
              <i class="bi bi-toggle-off text-secondary fs-2" v-else></i>
            </a>
            <button
              class="btn btn-secondary"
              v-if="hasRoles(['admin'])"
              type="button"
              @click.stop.prevent="revealPassword(user.syspk_user)"
            >
              <i class="bi bi-eye-fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-success d-none">Search</button>
  </form>
  <PaginationControl
    v-show="users !== null && users.length !== 0"
    @page-change="handlePageChange"
    :page="paginationSearchModel.currentPage"
    :pages="paginationSearchModel.totalPages"
    :items="paginationSearchModel.totalItems"
  />
  <MediumSizedModal :title="modalTitle" :show="showModal" @close="toggleModal">
    <form @submit.prevent="handleSave">
      <div class="form-group">
        <label>Username</label>
        <input
          required
          class="form-control"
          v-model="currentUser.name_user"
          :readonly="currentUser.syspk_user > 0"
        />
      </div>
      <div class="form-group">
        <label>Fullname</label>
        <input required class="form-control" v-model="currentUser.fullname_user" />
      </div>
      <div v-if="hasRequiredRole(['admin'])" class="form-group">
        <label>City ID</label>
        <input required class="form-control" v-model="currentUser.cityid_user" />
      </div>
      <div v-if="currentUser.syspk_user === 0" class="form-group">
        <label>Password</label>
        <div class="input-group">
          <input
            :type="passwordVisible ? 'text' : 'password'"
            required
            minlength="8"
            class="form-control"
            v-model="currentUser.pword_user"
          />
          <span
            style="cursor: pointer"
            class="input-group-text"
            @click="togglePasswordVisibility"
          >
            <i :class="passwordVisible ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'"></i>
          </span>
        </div>
      </div>

      <div v-if="currentUser.syspk_user === 0" class="form-group">
        <label>Mobile No</label>
        <input
          maxlength="11"
          minlength="11"
          required
          class="form-control"
          v-model="currentUser.mobileno_user"
        />
      </div>

      <div
        v-if="currentUser.syspk_user === 0 || hasRequiredRole(['admin'])"
        class="form-group"
      >
        <label>City/Municipality:</label>
        <select class="form-select" required v-model="currentUser.assignedcityids_user">
          <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
            {{ city.name_mun }}
          </option>
        </select>
      </div>

      <div></div>

      <div class="form-group text-end my-2">
        <button
          v-if="hasLocation"
          type="button"
          @click="locateUser(currentUser.syspk_user)"
          class="btn btn-primary"
        >
          <i class="bi bi-geo-alt-fill"></i> Locate
        </button>
      </div>
      <!-- Bootstrap Tabs -->
      <ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="roles-tab"
            data-bs-toggle="tab"
            data-bs-target="#roles"
            type="button"
            role="tab"
            aria-controls="roles"
            aria-selected="true"
          >
            Roles
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="brgys-tab"
            data-bs-toggle="tab"
            data-bs-target="#brgys"
            type="button"
            role="tab"
            aria-controls="brgys"
            aria-selected="false"
          >
            Brgys
          </button>
        </li>
      </ul>

      <div class="tab-content mt-3" id="myTabContent">
        <!-- Roles Tab -->
        <div
          class="tab-pane fade show active"
          id="roles"
          role="tabpanel"
          aria-labelledby="roles-tab"
        >
          <h5 class="my-2">Selected Roles:</h5>
          <div style="min-height: 150px" class="bg-light px-2">
            <a
              href="#"
              class="text-decoration-none text-danger"
              aria-label="Remove"
              v-for="role in selectedRolesArray"
              :key="role"
              @click="removeRole(role)"
            >
              <span class="badge bg-secondary me-2 pb-1 mt-1">
                {{ role }} <span class="text-danger">X</span>
              </span>
            </a>
          </div>
          <h5 class="my-2">Available Roles:</h5>
          <div class="row">
            <div class="col-6 col-lg-4" v-for="(role, index) in presetRoles" :key="role">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input me-2"
                  :id="'r_' + index"
                  :checked="selectedRoles.has(role)"
                  @change="toggleRole(role)"
                />
                <label :for="'r_' + index">{{ role }}</label>
              </div>
            </div>
          </div>
        </div>

        <!-- Brgys Tab -->
        <div class="tab-pane fade" id="brgys" role="tabpanel" aria-labelledby="brgys-tab">
          <h4 class="mt-2">Assigned Barangays</h4>
          <div style="min-height: 100px" class="bg-light p-2">
            <a
              href="#"
              class="me-2"
              @click.prevent="toggleAssignedBrgys(brgy)"
              v-for="brgy in selectedBrgys"
              :key="brgy.id_brgy"
            >
              <span class="badge bg-secondary"
                >{{ brgy.name_brgy }} <span class="text-danger">X</span>
              </span>
            </a>
          </div>
          <div class="row p-2">
            <h4 class="mt-2">Available Barangays</h4>
            <div
              class="form-check col-lg-6"
              v-for="brgy in availableBrgys"
              :key="brgy.id_brgy"
            >
              <input
                type="checkbox"
                class="form-check-input"
                :checked="isBrgyAssigned(brgy)"
                :id="'br_' + brgy.id_brgy"
                @change="toggleAssignedBrgys(brgy)"
              />
              <label :for="'br_' + brgy.id_brgy">{{ brgy.name_brgy }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mt-4">
        <button class="btn btn-success w-100">Save Changes</button>
      </div>
    </form>
  </MediumSizedModal>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import PaginationControl from "../PaginationControl.vue";
import userService from "@/services/userService";
import spinnerService from "@/services/spinnerService";
import MediumSizedModal from "../modals/MediumSizedModal.vue";
import areaService from "@/services/areaService";
import confirmationService from "@/services/confirmationService";
import toastService from "@/services/toastService";
import DOMPurify from "dompurify";

import {
  extractAssignedBrgys,
  getPresetRoles,
  processRolesString,
  roles,
} from "@/utils/config";
import { getUserRoles, hasRoles } from "@/utils/auth";
export default {
  components: {
    PaginationControl,
    MediumSizedModal,
  },
  setup() {
    const users = ref([]);
    const currentUser = ref({});
    const showModal = ref(false);
    const modalTitle = ref("User Information");
    const cities = ref([]);
    const searchModel = ref({ currentPage: 1, totalPages: 1, totalItems: 0 });
    const paginationSearchModel = ref({ currentPage: 1, totalPages: 1, totalItems: 0 });
    const selectedRoles = ref(new Set());
    const assignedBrgys = ref(new Set());
    const availableBrgys = ref([]);
    const selectedRolesArray = computed(() => Array.from(selectedRoles.value));
    const presetRoles = ref([]);
    const highlightMatchingText = (text) => {
      if (!searchModel.value.assignedBarangays || !text) return text;
      const regex = new RegExp(`(${searchModel.value.assignedBarangays})`, "gi");
      // Replace matching text with <strong> tags
      const highlightedText = text.replace(regex, "<strong>$1</strong>");
      return DOMPurify.sanitize(highlightedText);
    };
    const hasRequiredRole = (requiredRoles) => {
      return requiredRoles.some((role) => getUserRoles().includes(role));
    };
    const selectedBrgys = computed(() => {
      return availableBrgys.value.filter((brgy) => assignedBrgys.value.has(brgy.id_brgy));
    });
    const toggleModal = () => {
      showModal.value = !showModal.value;
    };
    const searchUsers = async () => {
      spinnerService.show();
      searchModel.value.currentPage = 1;
      const response = await userService.searchUsers(searchModel.value);
      users.value = response.users;
      paginationSearchModel.value = searchModel.value;
      paginationSearchModel.value.totalPages = response.totalPages;
      paginationSearchModel.value.totalItems = response.totalItems;
      spinnerService.hide();
    };
    const handlePageChange = async (page) => {
      paginationSearchModel.value.currentPage = page;
      spinnerService.show();
      const response = await userService.searchUsers(paginationSearchModel.value);
      paginationSearchModel.value.totalItems = response.totalItems;
      paginationSearchModel.value.totalPages = response.totalPages;
      paginationSearchModel.value.currentPage = response.currentPage;
      users.value = response.users;
      spinnerService.hide();
    };

    const handleToggleUserVerified = async (user) => {
      const confirmed = await confirmationService.show(
        "Are you sure you want to proceed?"
      );
      if (!confirmed) return;
      const state = user.isverified_user === 1 ? 0 : 1;
      const response = await userService.toggleVerifiedStatus(user.syspk_user, state);
      if (response.success) {
        user.isverified_user = state;
        toastService.success("Action successful");
      } else {
        toastService.error(response.message);
      }
    };

    const toggleRole = (role) => {
      if (selectedRoles.value.has(role)) {
        selectedRoles.value.delete(role);
      } else {
        selectedRoles.value.add(role);
      }
    };

    const locateUser = () => {
      const url = `https://www.google.com/maps?q=${currentLocation.value.lat_loc},${currentLocation.value.lng_loc}`;
      // Open the URL in a new tab
      window.open(url, "_blank");
    };
    const handleSave = async () => {
      const confirmed = await confirmationService.show(
        "Are you sure you want to save changes?"
      );
      if (!confirmed) return;

      currentUser.value.assignedcityids_user = String(
        currentUser.value.assignedcityids_user
      );
      spinnerService.show();
      currentUser.value.pcrole_user = selectedRolesArray.value.join(",");
      currentUser.value.assignedbrgyids_user = Array.from(assignedBrgys.value).join(",");
      const response = await userService.saveUserAssignments(currentUser.value);
      spinnerService.hide();
      if (response.success) {
        toastService.success("Action sucessful!");
        toggleModal();
      } else {
        toastService.error(response.message);
      }
    };

    // Remove role
    const removeRole = (role) => {
      selectedRoles.value.delete(role);
    };
    const handleSearch = () => {
      searchUsers();
    };
    const hasLocation = ref(false);
    const currentLocation = ref({});
    const handleItemClick = async (user) => {
      console.log(user);
      hasLocation.value = false;
      spinnerService.show();
      const response = await userService.getLastKnownLocation(user.syspk_user);
      if (response.lat_loc === 0 || response.lng_loc === 0) {
        hasLocation.value = false;
      } else {
        hasLocation.value = true;
      }
      currentLocation.value = response;
      currentUser.value = { ...user };
      selectedRoles.value = new Set(processRolesString(user.pcrole_user));
      presetRoles.value = getPresetRoles(); //{ ...selectedRolesArray.value };
      assignedBrgys.value = new Set(
        extractAssignedBrgys(currentUser.value.assignedbrgyids_user)
      );
      toggleModal();
      spinnerService.hide();
    };

    const handleToggleUser = async (user) => {
      const enabled = user.isenabled === 1 ? 0 : 1;
      let action = enabled === 0 ? "disable" : "enable";
      const confirmed = await confirmationService.show(
        `Are you sure you want to ${action} this user?`
      );
      if (!confirmed) return;
      spinnerService.show();
      const response = await userService.toggleUser({
        id: user.syspk_user,
        state: enabled,
      });
      spinnerService.hide();
      if (response.success) {
        user.isenabled = enabled;
        toastService.success("Action successful!");
      } else {
        toastService.error(response.message);
      }
    };
    const isBrgyAssigned = (brgy) => {
      return assignedBrgys.value.has(brgy.id_brgy);
    };
    const toggleAssignedBrgys = (brgy) => {
      const brgyId = brgy.id_brgy;
      if (assignedBrgys.value.has(brgyId)) {
        assignedBrgys.value.delete(brgyId);
      } else {
        assignedBrgys.value.add(brgyId);
      }
    };

    const revealPassword = async (id) => {
      const response = await userService.revealPassword(id);
      if (response.success) {
        alert(response.message);
      } else {
        toastService.error(response.message);
      }
    };

    const handleAddUser = () => {
      hasLocation.value = false;
      currentUser.value = { syspk_user: 0 };
      selectedRoles.value = new Set(processRolesString(""));
      presetRoles.value = getPresetRoles(); //{ ...selectedRolesArray.value };
      assignedBrgys.value = new Set(extractAssignedBrgys("0"));
      toggleModal();
    };
    const passwordVisible = ref(false);

    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };

    onMounted(async () => {
      cities.value = await areaService.getMunicipalities();
      availableBrgys.value = await areaService.getBrgyForUserRoles();
      searchUsers();
    });

    return {
      searchModel,
      users,
      handlePageChange,
      handleSearch,
      paginationSearchModel,
      handleItemClick,
      currentUser,
      showModal,
      toggleModal,
      modalTitle,
      cities,
      handleToggleUser,
      selectedRolesArray,
      passwordVisible,
      togglePasswordVisibility,
      removeRole,
      presetRoles,
      toggleRole,
      selectedRoles,
      handleSave,
      selectedBrgys,
      toggleAssignedBrgys,
      availableBrgys,
      isBrgyAssigned,
      hasLocation,
      locateUser,
      handleAddUser,
      hasRequiredRole,
      roles,
      handleToggleUserVerified,
      highlightMatchingText,
      hasRoles,
      revealPassword,
    };
  },
};
</script>
