<template>
  <h3><i class="bi bi-clock-history me-1"></i> User Logs</h3>
  <form @submit.prevent="handleSearch">
    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th>Type</th>
            <th>Platform</th>
            <th>Status</th>
            <th>Remarks</th>
            <th>Date</th>
          </tr>
          <tr>
            <th>
              <input
                v-model="ulogSearchParams.type_ulog"
                class="form-control"
                type="text"
              />
            </th>
            <th>
              <input
                v-model="ulogSearchParams.platform_ulog"
                class="form-control"
                type="text"
              />
            </th>
            <th>
              <input
                v-model="ulogSearchParams.status_ulog"
                class="form-control"
                type="text"
              />
            </th>
            <th>
              <input
                v-model="ulogSearchParams.remarks_ulog"
                class="form-control"
                type="text"
              />
            </th>
            <th>
              <input
                v-model="ulogSearchParams.date_ulog"
                class="form-control"
                type="text"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ulog in userlogs" :key="ulog.syspk_ulog">
            <td>{{ ulog.type_ulog }}</td>
            <td>{{ ulog.platform_ulog }}</td>
            <td>{{ ulog.status_ulog }}</td>
            <td>{{ ulog.remarks_ulog }}</td>
            <td>{{ ulog.date_ulog }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button type="submit" class="d-none"></button>
  </form>
  <PaginationControl
    @page-change="onPageChanged"
    :page="p.currentPage"
    :items="p.totalItems"
    :pages="p.totalPages"
  />
</template>

<script setup>
import spinnerService from "@/services/spinnerService";
import userService from "@/services/userService";
import { onMounted, ref } from "vue";
import PaginationControl from "@/components/PaginationControl.vue";
const p = ref({ currentPage: 1, totalItems: 0, totalPages: 1 });
const userlogs = ref([]);
const ulogSearchParams = ref({});
const paginationSearchOptions = ref({});
const handleSearch = async () => {
  searchLogs(1, ulogSearchParams.value);
};

const searchLogs = async (page, params) => {
  spinnerService.show();
  const resp = await userService.searchUserLogs(page, params);
  userlogs.value = resp.userLogs;
  p.value.totalItems = resp.totalItems;
  p.value.totalPages = resp.totalPages;
  p.value.currentPage = page;
  spinnerService.hide();
};

const onPageChanged = async (page) => {
  paginationSearchOptions.value = { ...ulogSearchParams.value };
  await searchLogs(page, paginationSearchOptions.value);
  p.value.currentPage = page;
};
onMounted(() => {
  handleSearch();
});
</script>
