<template>
  <h3><i class="bi bi-gear-wide-connected"></i> Admin Settings</h3>
  <div class="table-responsive">
    <table class="table align-middle">
      <thead>
        <tr>
          <th>Action</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="action in actions" :key="action.syspk_action">
          <td>{{ action.userpk_action }}</td>
          <td>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="switch-{{ action.syspk_action }}"
                :checked="action.isallowed_action === 1"
                @click.stop.prevent="toggleAction(action)"
              />
              <label class="form-check-label" for="switch-{{ action.syspk_action }}">
                {{ action.isallowed_action === 1 ? "Enabled" : "Disabled" }}
              </label>
            </div>
          </td>
        </tr>
        <tr v-if="actions.length === 0">
          <td class="text-muted" colspan="2">No records found.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import confirmationService from "@/services/confirmationService";
import toastService from "@/services/toastService";
import userService from "@/services/userService";
import { onMounted, ref } from "vue";

const actions = ref({});

const toggleAction = async (action) => {
  const confirmed = await confirmationService.show("Are you sure you want to continue?");
  if (!confirmed) return;
  const newValue = action.isallowed_action == 1 ? 0 : 1;
  const result = await userService.toggleAction(action.syspk_action, newValue);
  if (result.success) {
    toastService.success("Action successful");
    getActions();
  } else {
    toastService.error(result.message);
  }
};

const getActions = async () => {
  const result = await userService.getActions();
  actions.value = result;
};
onMounted(() => {
  getActions();
});
</script>
