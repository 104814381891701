<template>
  <h3><i class="bi bi-clock-history me-1"></i> Voter Logs</h3>
  <EventsTable />
</template>
<script>
import EventsTable from "@/components/logs/EventsTable.vue";

export default {
  components: {
    EventsTable,
  },
  setup() {},
};
</script>
