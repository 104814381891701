<template>
  <div class="row">
    <!-- Card for Total Number of People -->
    <div class="col-md-4 mb-4">
      <div class="card bg-light border-0 shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-people-fill fs-2 text-warning me-3"></i>
            <div class="w-100">
              <h5 class="card-title text-muted">Expected Voters</h5>
              <p
                v-show="
                  voterSummarry.totalSupporters + voterSummarry.totalNonSupporters > 0
                "
                class="card-text fs-4 mb-0 d-flex justify-content-between align-items-center fw-bold"
              >
                <span>
                  <NumberCounter
                    :startValue="startValue"
                    :endValue="
                      voterSummarry.totalSupporters + voterSummarry.totalNonSupporters
                    "
                    :duration="0.5"
                  />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card for Total Number of Supporters -->
    <div class="col-md-4 mb-4">
      <div class="card bg-light border-0 shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-person-hearts fs-2 me-3" :class="getColor()"></i>
            <div>
              <h5 class="card-title text-muted">Supporters</h5>
              <p
                class="card-text fs-4 mb-0 fw-bold"
                v-show="voterSummarry.totalSupporters > 0"
              >
                <NumberCounter
                  :startValue="startValue"
                  :endValue="voterSummarry.totalSupporters"
                  :duration="0.5"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card for Additional Metric 1 -->
    <div class="col-md-4 mb-4">
      <div class="card bg-light border-0 shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-person-fill-x fs-2 text-primary me-3"></i>
            <div>
              <h5 class="card-title text-muted">Non-Supporters</h5>
              <p class="card-text fs-4 mb-0 fw-bold">
                <NumberCounter
                  :startValue="startValue"
                  :endValue="voterSummarry.totalNonSupporters"
                  :duration="0.5"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card for Additional Metric 2 -->
    <div class="col-md-4 mb-4">
      <div class="card bg-light border-0 shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-check-all fs-2 text-success me-3"></i>
            <div>
              <h5 class="card-title text-muted">Completed</h5>
              <p class="card-text fs-4 mb-0 fw-bold">
                <NumberCounter
                  :startValue="startValue"
                  :endValue="scanningSummary.completed"
                  :duration="0.5"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card for Additional Metric 3 -->
    <div class="col-md-4 mb-4">
      <div class="card bg-light border-0 shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-check fs-2 text-info me-3"></i>
            <div>
              <h5 class="card-title text-muted">Processing</h5>
              <p class="card-text fs-4 mb-0 fw-bold">
                <NumberCounter
                  :startValue="startValue"
                  :endValue="scanningSummary.partially"
                  :duration="0.5"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-4">
      <div class="card bg-light border-0 shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-hourglass-top fs-2 text-secondary me-3"></i>
            <div>
              <h5 class="card-title text-muted">Pending</h5>
              <p class="card-text fs-4 mb-0 fw-bold">
                <NumberCounter
                  :startValue="startValue"
                  :endValue="scanningSummary.pending"
                  :duration="0.5"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TabsWrapper>
    <Tab title="Overview">
      <h3 class="fw-bold">Supporters vs Non-Supporters</h3>
      <div class="progress-container">
        <div :class="getProgressColor()" id="leftProgress"></div>
        <div class="progress-bar right" id="rightProgress"></div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="row">
          <h4 class="fw-bold mt-2">{{ (progSup * 100).toFixed(2) }}%</h4>
          <p>Supporters</p>
        </div>
        <div class="text-end">
          <h4 class="fw-bold mt-2 text-end">{{ ((1 - progSup) * 100).toFixed(2) }}%</h4>
          <p>Non-Supporters</p>
        </div>
      </div>
      <div class="text-center">
        <h4 class="fw-bold">
          {{
            Math.ceil(
              (voterSummarry.totalNonSupporters - voterSummarry.totalSupporters) / 2
            )
          }}
        </h4>
        <p>Break-even Point</p>
      </div>
    </Tab>
    <Tab title="Graph">
      <VMChart :id="id" />
    </Tab>
  </TabsWrapper>
</template>

<script>
import dashboardService from "@/services/dashboardService";
import signalRService from "@/services/signalRService";
import { onMounted, watch, onUnmounted, ref } from "vue";
import NumberCounter from "../customs/NumberCounter.vue";
import { debounce } from "@/utils/debounce";
import TabsWrapper from "../tabs/TabsWrapper.vue";
import Tab from "../tabs/Tab.vue";
import VMChart from "../charts/VMChart.vue";
export default {
  components: {
    NumberCounter,
    TabsWrapper,
    Tab,
    VMChart,
  },
  props: {
    id: { type: Number, default: () => 0 },
  },
  emits: ["win", "progress-updated"],
  setup(props, { emit }) {
    const voterSummarry = ref({
      totalSupporters: 0,
      totalNonSupporters: 0,
      totalRawVoters: 0,
      expectedTurnout: 0.0,
    });
    const startValue = ref(0);
    const endValue = ref(1000);

    const updateValues = () => {
      // Example of changing values dynamically
      startValue.value = endValue.value;
      endValue.value = Math.floor(Math.random() * 2000);
    };
    const setProgress = (leftPercent, rightPercent) => {
      const leftBar = document.getElementById("leftProgress");
      const rightBar = document.getElementById("rightProgress");

      leftBar.style.width = leftPercent + "%"; // Set width for left side
      rightBar.style.width = rightPercent + "%"; // Set width for right side
    };
    const scanningSummary = ref({
      pending: 0,
      partially: 0,
      completed: 0,
    });
    const progSup = ref(0);
    const getVoterSummary = async () => {
      voterSummarry.value = await dashboardService.getVoterSummary(props.id);
      progSup.value =
        voterSummarry.value.totalSupporters / voterSummarry.value.totalRawVoters;
      const progNon = (1 - progSup.value) * 100;
      setProgress(progSup.value * 100, progNon);
    };
    const emitWinning = () => {
      const { completed, partially, pending } = scanningSummary.value || {};
      const { totalNonSupporters } = voterSummarry.value || {};

      // Check for valid numbers (not undefined, null, NaN)
      const areValuesValid = [completed, partially, pending, totalNonSupporters].every(
        (val) => typeof val === "number" && !isNaN(val)
      );

      if (!areValuesValid) {
        // Skip if there are invalid values
        return;
      }

      // Emit only if the logic holds true
      if (completed + partially > pending + totalNonSupporters) {
        emit("win", "Congratulations!");
      }
    };
    const emitProgress = () => {
      const { completed, partially } = scanningSummary.value || {};
      const { totalSupporters } = voterSummarry.value || {};

      // Check if all required values are valid numbers
      const areValuesValid = [completed, partially, totalSupporters].every(
        (val) => typeof val === "number" && !isNaN(val)
      );

      // If any value is not valid, emit 0
      if (!areValuesValid || totalSupporters === 0) {
        emit("progress-updated", 0);
        return;
      }

      // Calculate progress
      const prog = (completed + partially / 2) / totalSupporters;
      emit("progress-updated", prog);
    };
    const getScanSummary = async () => {
      scanningSummary.value = await dashboardService.getScanningSummary(props.id);
      emitWinning();
      emitProgress();
    };

    const getProgressColor = () => {
      if (props.id === 3) {
        return "progress-bar kananga";
      } else {
        return "progress-bar left";
      }
    };

    const getColor = () => {
      if (props.id === 3) {
        return "text-success";
      } else {
        return "text-danger";
      }
    };
    // Create a debounced version of fetchData
    const debouncedGetVoterSummary = debounce(getVoterSummary, 300); // Adjust debounce delay as needed
    const debouncedGetScanSummary = debounce(getScanSummary, 300); // Adjust debounce delay as needed
    onMounted(() => {
      signalRService.subscribeToEvent("VoterSideUpdated", debouncedGetVoterSummary);
      signalRService.subscribeToEvent("VoterScanned", debouncedGetScanSummary);
      signalRService.subscribeToEvent("TEST", (msg) => {
        alert(msg);
      });
      // getVoterSummary();
      // getScanSummary();
    });

    watch(
      () => props.id,
      async () => {
        await getVoterSummary();
        await getScanSummary();
      }
    );

    onUnmounted(() => {
      signalRService.connection.off("VoterSideUpdated", debouncedGetVoterSummary);
      signalRService.connection.off("VoterScanned", debouncedGetScanSummary);
      signalRService.connection.off("TEST");
    });

    return {
      voterSummarry,
      scanningSummary,
      startValue,
      endValue,
      updateValues,
      progSup,
      getProgressColor,
      getColor,
    };
  },
};
</script>

<style scoped>
.card {
  border-radius: 0.75rem;
}
.card-body {
  display: flex;
  align-items: center;
}
.card-title {
  margin-bottom: 0.5rem;
}
.card-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.turnout-percentage {
  margin-left: auto;
  text-align: right; /* Optional: Align text to the right if needed */
}

.progress-container {
  display: flex;
  width: 100%; /* Full width */
  background-color: #e0e0e0; /* Background color for the container */
  border-radius: 25px; /* Rounded corners */
  overflow: hidden; /* Prevent overflow */
  position: relative; /* Allows absolute positioning of the bars */
}

.progress-bar {
  height: 30px; /* Height of the progress bar */
  transition: width 0.5s; /* Smooth transition for width changes */
}

.left {
  background-color: #fd0707; /* Color for the left progress */
  width: 0; /* Initialize width */
}

.kananga {
  background-color: #2cf858; /* Color for the left progress */
  width: 0; /* Initialize width */
}

.right {
  background-color: #2196f3; /* Color for the right progress */
  position: absolute; /* Allow it to overlap */
  right: 0; /* Position it to the right */
  width: 0; /* Initialize width */
}
</style>
