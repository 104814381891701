<template>
  <div class="table-responsive">
    <table class="table alig-middle">
      <thead>
        <tr>
          <th style="min-width: 120px">City</th>
          <th style="min-width: 250px">Brgy</th>
          <th>
            Voters
            <span
              id="info-icon"
              class="bi bi-question"
              title="Total expected voters, based on previous election results turn-out percentage."
            ></span>
          </th>
          <th>
            Supporters
            <span
              id="info-icon"
              class="bi bi-question"
              title="Total Confirmed Supporters"
            ></span>
          </th>
          <th>
            Lead
            <span
              id="info-icon"
              class="bi bi-question"
              title="Computed Lead or Deficit based on supporter vs non supporter data"
            ></span>
          </th>
          <th>
            BP
            <span
              id="info-icon"
              class="bi bi-question"
              title="Break-even points: The number of people we need to convert as supporters in order to level the playing field."
            ></span>
          </th>
          <th>
            {{ customizationService.getPLeaderLabel(props.id) }}
            <span id="info-icon" class="bi bi-question" title="Principal Leaders"></span>
          </th>
          <th>
            {{ customizationService.getLeaderLabel(props.id) }}
            <span id="info-icon" class="bi bi-question" title="Leaders"></span>
          </th>
          <th>
            {{ customizationService.getSubLeaderLabel(props.id) }}
            <span id="info-icon" class="bi bi-question" title="Sub-Leaders"></span>
          </th>
          <th>
            Members
            <span id="info-icon" class="bi bi-question" title="Members"></span>
          </th>
          <th>
            Completed
            <span id="info-icon" class="bi bi-question" title="Done Voting"></span>
          </th>
          <th>
            Partial
            <span id="info-icon" class="bi bi-question" title="At the precinct"></span>
          </th>
          <th>
            Pending
            <span id="info-icon" class="bi bi-question" title="Waiting to show up"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isFetching">
          <td class="text-center" colspan="12">
            <div class="spinner-container">
              <div class="spinner-border text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </td>
        </tr>
        <tr
          :class="[
            {
              'table-primary': getLead(brgy.totalvoter, brgy.teamok) <= 0,
              'table-danger': getLead(brgy.totalvoter, brgy.teamok) > 0 && props.id !== 3,
              'table-success':
                getLead(brgy.totalvoter, brgy.teamok) > 0 && props.id === 3,
            },
            {
              'table-success': won(
                brgy.completed,
                brgy.partial,
                brgy.totalvoter - brgy.teamok,
                brgy.pending
              ),
            },
          ]"
          v-for="brgy in brgys"
          :key="brgy.id_brgy"
        >
          <td>
            <i
              v-if="isReadyForGeoTagging(brgy.totalvoter, brgy.teamok)"
              class="bi bi-check text-success"
            ></i>
            <span
              class="badge bg-success"
              v-if="
                won(
                  brgy.completed,
                  brgy.partial,
                  brgy.totalvoter - brgy.teamok,
                  brgy.pending
                )
              "
              >WIN</span
            >
            {{ brgy.city_voter }}
          </td>
          <td style="min-width: 250px">{{ brgy.brgy_voter }}</td>
          <td class="text-center">{{ brgy.totalvoter }}</td>
          <td class="text-center">{{ brgy.teamok }}</td>
          <td class="text-center">{{ getLead(brgy.totalvoter, brgy.teamok) }}</td>
          <td class="text-center">
            {{ getBreakEvenPoint(brgy.totalvoter, brgy.teamok) }}
          </td>
          <td class="text-center">
            <a
              href="#"
              class="text-decoration-none text-dark"
              @click.prevent="handleClickAction(brgy.brgyid_voter, 'pleaders')"
              >{{ brgy.pleaders }}</a
            >
          </td>
          <td class="text-center">
            <a
              href="#"
              class="text-decoration-none text-dark"
              @click.prevent="handleClickAction(brgy.brgyid_voter, 'leaders')"
              >{{ brgy.leaders }}</a
            >
          </td>
          <td class="text-center">
            <a
              href="#"
              class="text-decoration-none text-dark"
              @click.prevent="handleClickAction(brgy.brgyid_voter, 'subleaders')"
            >
              {{ brgy.subleaders }}</a
            >
          </td>
          <td class="text-center">
            <a
              href="#"
              class="text-decoration-none text-dark"
              @click.prevent="handleClickAction(brgy.brgyid_voter, 'members')"
              >{{ brgy.members }}</a
            >
          </td>
          <td class="text-center">
            <a
              href="#"
              class="text-decoration-none text-dark"
              @click.prevent="handleClickAction(brgy.brgyid_voter, 'completed')"
            >
              {{ brgy.completed }}
            </a>
          </td>
          <td class="text-center">
            <a
              href="#"
              class="text-decoration-none text-dark"
              @click.prevent="handleClickAction(brgy.brgyid_voter, 'partial')"
              >{{ brgy.partial }}</a
            >
          </td>
          <td class="text-center">
            <a
              href="#"
              class="text-decoration-none text-dark"
              @click.prevent="handleClickAction(brgy.brgyid_voter, 'pending')"
              >{{ brgy.pending }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <PaginationControl
    @page-change="onPageChanged"
    :page="pagination.currentPage"
    :pages="pagination.totalPages"
    :items="pagination.totalItems"
  />
  <MediumSizedModal :title="modalTitle" :show="showModal" @close="toggleModal">
    <VoterByStatus
      v-if="modalAction === 'by-status'"
      :searchparams="statusSearchParams"
    />
    <VoterByType v-if="modalAction === 'by-type'" :searchparams="typeSearchParams" />
  </MediumSizedModal>
</template>

<script>
import dashboardService from "@/services/dashboardService";
import signalRService from "@/services/signalRService";
import { debounce } from "@/utils/debounce";
import { onMounted, ref, watch } from "vue";
import PaginationControl from "../PaginationControl.vue";
import VoterByStatus from "./VoterByStatus.vue";
import MediumSizedModal from "../modals/MediumSizedModal.vue";
import VoterByType from "./VoterByType.vue";
import customizationService from "@/services/customizationService";
export default {
  components: {
    PaginationControl,
    VoterByStatus,
    MediumSizedModal,
    VoterByType,
  },
  props: {
    id: { type: Number, default: () => 0 },
  },
  setup(props) {
    const brgys = ref([]);
    const pagination = ref({ currentPage: 1, totalPages: 1, totalItems: 0 });
    const isFetching = ref(false);
    const showModal = ref(false);
    const statusSearchParams = ref({ id: 0, state: null });
    const typeSearchParams = ref({ id: 0, state: null });
    const modalTitle = ref("Additional Information");
    const toggleModal = () => {
      showModal.value = !showModal.value;
    };
    // Fetch data function
    const onPageChanged = (page) => {
      pagination.value.currentPage = page;
      fetchData();
    };
    const won = (completed, partial, nonsupporters, pending) => {
      const voted = completed + partial;
      const threat = nonsupporters + pending;
      return voted > threat;
    };
    const getLead = (total, supporters) => {
      const nonSupporters = total - supporters;
      // Calculate the difference between supporters and non-supporters
      const lead = Number(supporters) - Number(nonSupporters);
      return lead;
    };
    const modalAction = ref("by-type");
    const handleClickAction = (id, action) => {
      modalTitle.value = action.toUpperCase();
      switch (action) {
        case "completed":
        case "partial":
        case "pending":
          modalAction.value = "by-status";
          getVoterScanSummaryByStatus(id, action);
          break;
        case "pleaders":
        case "leaders":
        case "subleaders":
        case "members":
          modalAction.value = "by-type";
          getVotersByType(id, action);
          break;
      }
    };

    const getVotersByType = async (id, action) => {
      typeSearchParams.value.id = id;
      typeSearchParams.value.state = action;
      toggleModal();
    };

    const getVoterScanSummaryByStatus = async (id, action) => {
      statusSearchParams.value.id = id;
      statusSearchParams.value.state = action;
      toggleModal();
    };

    const fetchData = async () => {
      isFetching.value = true;
      try {
        const response = await dashboardService.getDashboardBreakdown(
          props.id,
          pagination.value.currentPage
        );
        brgys.value = response.brgys;
        pagination.value.totalItems = response.totalItems;
        pagination.value.totalPages = response.totalPages;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        isFetching.value = false;
      }
    };
    const isReadyForGeoTagging = (total, sup) => {
      return sup / total >= 0.3;
    };
    const getBreakEvenPoint = (total, sup) => {
      const nonsupporters = total - sup;
      return Math.ceil((nonsupporters - sup) / 2);
    };
    // Create a debounced version of fetchData
    const debouncedFetchData = debounce(fetchData, 300); // Adjust debounce delay as needed

    // Subscribe to SignalR events
    onMounted(() => {
      signalRService.subscribeToEvent("VoterSideUpdated", debouncedFetchData);
      signalRService.subscribeToEvent("VoterScanned", debouncedFetchData);
      //fetchData();
    });

    watch(
      () => props.id,
      () => {
        fetchData();
      }
    );

    onMounted(() => {
      signalRService.connection.off("VoterSideUpdated", debouncedFetchData);
      signalRService.connection.off("VoterScanned", debouncedFetchData);
    });

    return {
      brgys,
      getLead,
      onPageChanged,
      pagination,
      isFetching,
      won,
      handleClickAction,
      toggleModal,
      statusSearchParams,
      showModal,
      modalTitle,
      typeSearchParams,
      modalAction,
      isReadyForGeoTagging,
      getBreakEvenPoint,
      props,
      customizationService,
    };
  },
};
</script>
<style scoped>
#info-icon {
  cursor: pointer;
  font-weight: bold;
  margin-left: 5px;
  background-color: lightgray;
  border-radius: 3px;
  color: blue;
}
#info-icon:hover {
  color: darkblue;
}
</style>
